import React from "react"
import styled from "styled-components"
// components
import TitleLeft from "./title_left"

const StyledCategory = styled.div`
  border: 3px solid #67a70c;
  box-sizing: border-box;
  height: 200px;
  padding: 20px;
`

const Category = ({ category }) => {
  const {
    heading,
    paragraph
  } = category
  return (
    <StyledCategory>
      <TitleLeft
        level={2}
        fontSize="large"
        heading={heading}
      />
      <p
        style={{
          fontSize: "15px",
          lineHeight: 1.5,
          margin: 0,
          whiteSpace: "pre-wrap"
        }}
      >
        <strong
          style={{
            background: "linear-gradient(transparent 70%, #ffd900 70%, #ffd900 100%)",
            fontWeight: "normal"
          }}
        >
          {paragraph}
        </strong>
      </p>
    </StyledCategory>
  )
}

export default Category
