import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
// components
import Paragraph from "../atoms/paragraph"

const StyledStep = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};

  @media (min-width: 768px) and (max-width: 959px) {
    grid-column-end: ${props => props.gridColumnEnd};
    grid-column-start: ${props => props.gridColumnStart};
  }
`

const Wrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  height: 200px;
  margin-bottom: ${props => props.marginBottom};
  margin-left: 15px;
  margin-top: ${props => props.marginTop};
  padding: 20px;
  position: relative;

  @media (max-width: 767px) {
    height: auto;
    min-height: 200px;
  }
`

const Step = ({
  reverse,
  displace,
  step,
  bullet
}) => {
  return (
    <StyledStep
      flexDirection={
        reverse
          ? "column-reverse"
          : "column"
      }
      gridColumnEnd={displace && "-1"}
      gridColumnStart={displace && "2"}
    >
      <GatsbyImage
        image={step.image}
        alt={step.alt}
      />
      <Wrapper
        marginBottom={reverse && "-100px"}
        marginTop={!reverse && "-100px"}
      >
        <h3
          style={{
            color: "#67a70c",
            display: "flex",
            fontSize: "large",
            lineHeight: 1.5,
            margin: 0,
            marginBottom: "10px"
          }}
        >
          <span style={{ marginRight: "5px" }}>
            {bullet}
          </span>
          {step.heading}
        </h3>
        <Paragraph
          fontSize="15px"
          whiteSpace="pre-wrap"
          paragraph={step.paragraph}
        />
      </Wrapper>
    </StyledStep>
  )
}

export default Step
