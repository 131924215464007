import React from "react"
import styled from "styled-components"
// components
import Paragraph from "../atoms/paragraph"

const StyledImage = styled.div`
  flex: ${props => props.flex};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTop};
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 75%;
  }
`

const Wrapper = styled.div`
  background: ${props => props.background};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  padding: 10px 0;
  position: absolute;
  right: ${props => props.right};
  top: ${props => props.top};
  width: ${props => props.width};
`

const Image = ({
  src,
  alt,
  label,
  ...rest
}) => {
  const props = {
    afterIn: {
      wrapper: {
        background: "rgba(255,255,255,0.75)",
        bottom: 0,
        left: 0,
        width: "100%"
      },
      paragraph: {
        fontSize: "large",
        fontWeight: "bold",
        textAlign: "center",
        paragraph: "AFTER"
      }
    },
    afterOut: {
      wrapper: {
        background: "rgba(255,255,255,0.75)",
        bottom: "15px",
        left: "-100px",
        width: "200px"
      },
      paragraph: {
        fontSize: "large",
        fontWeight: "bold",
        textAlign: "center",
        paragraph: "AFTER"
      }
    },
    beforeIn: {
      wrapper: {
        background: "rgba(0,0,0,0.75)",
        bottom: 0,
        left: 0,
        width: "100%"
      },
      paragraph: {
        color: "#ffffff",
        fontSize: "large",
        fontWeight: "bold",
        textAlign: "center",
        paragraph: "BEFORE"
      }
    },
    beforeOut: {
      wrapper: {
        background: "rgba(0,0,0,0.75)",
        right: "-100px",
        top: "15px",
        width: "200px"
      },
      paragraph: {
        color: "#ffffff",
        fontSize: "large",
        fontWeight: "bold",
        textAlign: "center",
        paragraph: "BEFORE"
      }
    }
  }
  return (
    <StyledImage {...rest}>
      <img
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        style={{
          left: 0,
          objectFit: "contain",
          position: "absolute",
          top: 0
        }}
      />
      {label &&
        <Wrapper {...props[label].wrapper}>
          <Paragraph {...props[label].paragraph} />
        </Wrapper>
      }
    </StyledImage>
  )
}

export default Image
