import React from "react"
import styled from "styled-components"
// modules
import useBlog from "../../modules/use_blog"
import Link from "../../modules/link"
// components
import TitleCenter from "../molecules/title_center"
import BeforeAfter from "../molecules/before_after"

const StyledCase = styled.div`
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
`

const OuterWrapper = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: 25px 0;
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Case = () => {
  const posts = useBlog()
  const { reference } = posts[0]
  return (
    <StyledCase>
      <OuterWrapper>
        <TitleCenter
          level={2}
          fontSize="20px"
          heading="施工事例"
        />
        <BeforeAfter
          marginBottom="10px"
          asymmetric={true}
          imageBefore={reference.before.url}
          altBefore="" //
          imageAfter={reference.after.url}
          altAfter="" //
        />
        <InnerWrapper>
          <Link
            to="/blog/case"
            background="#67a70c"
            color="#ffffff"
            display="block"
            fontSize="15px"
            padding="10px 50px"
            $background="#26610f"
          >
            施工事例一覧
          </Link>
        </InnerWrapper>
      </OuterWrapper>
    </StyledCase>
  )
}

export default Case
