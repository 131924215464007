import styled from "styled-components"

const Dot = styled.div`
  background: ${props => props.background};
  border-radius: 50%;
  cursor: ${props => props.cursor};
  height: 7px;
  margin-right: ${props => props.marginRight};
  width: 7px;
`

export default Dot
