import React from "react"
import styled from "styled-components"
import {
  useStaticQuery,
  graphql
} from "gatsby"
import { getImage } from "gatsby-plugin-image"
// components
import TitleCenter from "../molecules/title_center"
import Step from "../molecules/step"

const StyledWorkflow = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: 25px 0;
`

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;

  @media (max-width: 959px) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }

  @media (max-width: 767px) {
    grid-column-gap: 0;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
  }
`

const Workflow = () => {
  const {
    step_1,
    step_2,
    step_3,
    step_4,
    step_5
  } = useStaticQuery(graphql`
    query {
      step_1: file(relativePath: { eq: "step_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 737)
        }
      }
      step_2: file(relativePath: { eq: "step_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 737)
        }
      }
      step_3: file(relativePath: { eq: "step_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 737)
        }
      }
      step_4: file(relativePath: { eq: "step_4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 737)
        }
      }
      step_5: file(relativePath: { eq: "step_5.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 737)
        }
      }
    }
  `)
  const steps = [
    {
      image: getImage(step_1),
      alt: "", //
      heading: "お問い合わせ",
      paragraph: "ご自宅や所持している集合住宅等にてお困り事や気になる箇所がありましたらお気軽にお電話かメールフォームにてお問い合わせ下さい。"
    },
    {
      image: getImage(step_2),
      alt: "", //
      heading: "現地調査",
      paragraph: "お問い合わせ頂いた内容をお客様とヒアリングし、担当者が現地調査を致します。"
    },
    {
      image: getImage(step_3),
      alt: "", //
      heading: "報告書及び御見積書作成、打ち合わせ",
      paragraph: "現地調査終了後、担当スタッフにて現地で撮影した写真を使用し、報告書を作成致します。\n合わせて御見積書もお渡しさせて頂き、不明点等御座いましたら都度回答させて頂きます。\nご検討頂き、ご納得頂けた場合は工事内容・工期等を打合せし、責任を持って施行させて頂きます。"
    },
    {
      image: getImage(step_4),
      alt: "", //
      heading: "工事着工",
      paragraph: "当社の職人が足場組みから、防水・塗装工事を一貫施工で行います。"
    },
    {
      image: getImage(step_5),
      alt: "", //
      heading: "工事完了",
      paragraph: "工事が完了し、お客様との現場チェックを行いご確認頂いた上で完了・引き渡しとなります。"
    }
  ]
  return (
    <StyledWorkflow>
      <TitleCenter
        level={2}
        fontSize="20px"
        heading="サービスの流れ"
      />
      <Wrapper>
        {steps.map((step, index) => {
          return (
            <Step
              key={index}
              reverse={
                index % 2 !== 0
                  ? true
                  : false
              }
              displace={
                index === 4
                  ? true
                  : false
              }
              step={step}
              bullet={"0" + (index + 1) + "."}
            />
          )
        })}
      </Wrapper>
    </StyledWorkflow>
  )
}

export default Workflow
