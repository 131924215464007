import React from "react"
import styled from "styled-components"
// modules
import Link from "../../modules/link"
// components
import UnorderedList from "../atoms/unordered_list"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const Posts = ({
  posts,
  limit,
  slug,
  category
}) => {
  const length = posts.length > limit ? limit : posts.length
  return (
    <>
      <UnorderedList>
        {new Array(length).fill().map((_, index) => {
          const {
            id,
            date,
            formattedDate,
            title
          } = posts[index]
          return (
            <li key={id}>
              <Link
                to={`/blog/${slug}/${id}`}
                color="#000000"
                display="block"
                fontSize="15px"
                $lineHeight="1.5"
                overflow="hidden"
                $textOverflow="ellipsis"
                $whiteSpace="nowrap"
                textDecoration="underline"
              >
                <time
                  dateTime={date}
                  style={{
                    color: "#7d7d7d",
                    marginRight: "5px"
                  }}
                >
                  {formattedDate}
                </time>
                {title}
              </Link>
            </li>
          )
        })}
      </UnorderedList>
      {posts.length > limit &&
        <Wrapper>
          <Link
            to={`/blog/${slug}`}
            background="#67a70c"
            color="#ffffff"
            display="block"
            fontSize="15px"
            padding="10px 50px"
            $background="#26610f"
          >
            {`${category}一覧`}
          </Link>
        </Wrapper>
      }
    </>
  )
}

export default Posts
