import React from "react"
import styled from "styled-components"
// components
import Dot from "../atoms/dot"

const StyledDots = styled.div`
  display: flex;
  grid-column-end: ${props => props.gridColumnEnd};
  grid-column-start: ${props => props.gridColumnStart};
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
`

const Dots = ({
  length,
  current,
  handleClick,
  ...rest
}) => {
  return (
    <StyledDots {...rest}>
      {new Array(length).fill().map((_, index) => {
        return (
          <Dot
            key={index}
            background={
              index === current
                ? "#26610f"
                : "#ffffff"
            }
            cursor="pointer"
            marginRight={index + 1 < length && "10px"}
            onClick={() => handleClick(index)}
          />
        )
      })}
    </StyledDots>
  )
}

export default Dots
