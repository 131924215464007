import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { useMediaQuery } from "react-responsive"
// components
import Category from "../molecules/category"

const StyledCategories = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  position: relative;
  z-index: 0;
`

const OuterWrapper = styled.div`
  grid-column-end: ${props => props.gridColumnEnd};
  grid-column-start: ${props => props.gridColumnStart};
  left: ${props => props.left};
  padding-bottom: ${props => props.paddingBottom};
  position: ${props => props.position};
  top: ${props => props.top};
  width: ${props => props.width};
  z-index: ${props => props.zIndex};
`

const MiddleWrapper = styled.div`
  background: rgba(0,0,0,0.75);
  overflow: hidden;
  position: relative;
  height: 250px;
`

const InnerWrapper = styled.div`
  background: ${props => props.background};
  display: ${props => props.display};
  grid-column-gap: ${props => props.gridColumnGap};
  grid-row-gap: ${props => props.gridRowGap};
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-template-rows: ${props => props.gridTemplateRows};
  left: ${props => props.left};
  padding: ${props => props.padding};
  position: ${props => props.position};
  transform: ${props => props.transform};
  z-index: ${props => props.zIndex};
`

const Categories = () => {
  const isDesktop = useMediaQuery({ minWidth: 960 })
  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 959
  })
  const categories = [
    {
      heading: "仮設工事",
      paragraph: "修繕工事に伴う、各種足場・架払い・安全対策"
    },
    {
      heading: "躯体・下地補修工事",
      paragraph: "コンクリート・モルタルなど亀裂・浮き補修\nタイル・石など亀裂・浮き補修\n各種クリーニング"
    },
    {
      heading: "シーリング工事",
      paragraph: "窓廻り・目地・各種取り合い部・打ち替え"
    },
    {
      heading: "仕上げ工事",
      paragraph: "各種塗装地仕上げ"
    },
    {
      heading: "鉄部他塗装工事",
      paragraph: "ケレン洗浄後、防錆材塗装、各種仕上げ材塗布"
    },
    {
      heading: "防水工事",
      paragraph: "ウレタン防水、シート防水、アスファルト防水\nその他各種防水工事"
    },
    {
      heading: "その他",
      paragraph: "金物工事、手すり、避難ハッチなどの取替\n外構工事、門扉やフェンスなどのエクステリア工事"
    }
  ]
  return (
    <StyledCategories>
      <OuterWrapper
        left="0"
        position="absolute"
        top="0"
        width="100%"
        zIndex="-1"
      >
        <MiddleWrapper>
          <InnerWrapper
            left="50%"
            position="absolute"
            transform="translateX(-50%)"
            zIndex="-10"
          >
            <StaticImage
              src="../../images/categories.jpg"
              alt="" //
              layout="fixed"
              height={250}
            />
          </InnerWrapper>
        </MiddleWrapper>
      </OuterWrapper>
      <OuterWrapper
        gridColumnEnd="-2"
        gridColumnStart="2"
        paddingBottom="15px"
      >
        <h1
          style={{
            color: "#ffffff",
            fontSize: "xx-large",
            lineHeight: 1.5,
            margin: 0,
            padding: "50px 0",
            textAlign: "center"
          }}
        >
          <strong style={{ background: "linear-gradient(transparent 70%, #ffd900 70%, #ffd900 100%)" }}>
            修繕工事全般
          </strong>
          <br />
          お任せください！
        </h1>
        <InnerWrapper
          background="#ffffff"
          display="grid"
          gridColumnGap={(isDesktop || isTablet) && "10px"}
          gridRowGap="10px"
          gridTemplateColumns={
            isDesktop
              ? "auto auto auto"
              : isTablet
                ? "auto auto"
                : "auto"
          }
          gridTemplateRows="auto"
          padding="25px"
        >
          {categories.map((category, index) => {
            return (
              <Category
                key={index}
                category={category}
              />
            )
          })}
        </InnerWrapper>
      </OuterWrapper>
    </StyledCategories>
  )
}

export default Categories
