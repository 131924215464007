import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
// components
import TitleLeft from "../molecules/title_left"
import Paragraph from "../atoms/paragraph"

const StyledArea = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
`

const OuterWrapper = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: 15px 0;

  @media (min-width: 768px) {
    display: flex;
  }
`

const InnerWrapper = styled.div`
  background: #ffffff;
  border: ${props => props.border};
  flex: ${props => props.flex};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTop};
  padding: ${props => props.padding};
`

const Area = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const paragraph = "中野区とその周辺区を中心に修繕工事を承っております。\nその他の都内外の地域も対応可能ですので、お気軽にご相談ください。"
  return (
    <StyledArea>
      <OuterWrapper>
        <InnerWrapper
          flex={!isMobile && "1"}
          marginBottom={!isMobile && "100px"}
          padding="25px"
        >
          <TitleLeft
            level={2}
            fontSize="20px"
            heading="対応エリア"
          />
          <Paragraph
            fontSize="15px"
            marginBottom={isMobile && "10px"}
            marginRight={!isMobile && "50px"}
            whiteSpace="pre-wrap"
            paragraph={paragraph}
          />
          {isMobile &&
            <StaticImage
              src="../../images/area.png"
              alt="" //
              layout="fixed"
              width={250}
              style={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            />
          }
        </InnerWrapper>
        {!isMobile &&
          <InnerWrapper
            border="3px solid #e8ecef"
            marginLeft="-50px"
            marginTop="100px"
            padding="20px"
          >
            <StaticImage
              src="../../images/area.png"
              alt="" //
              layout="fixed"
              width={300}
            />
          </InnerWrapper>
        }
      </OuterWrapper>
    </StyledArea>
  )
}

export default Area
