import React from "react"
import styled from "styled-components"
import {
  useStaticQuery,
  graphql
} from "gatsby"
// components
import TitleLeft from "../molecules/title_left"
import Posts from "../molecules/posts"

const StyledNews = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  min-height: 200px;
  padding: 25px;
`

const News = () => {
  const { allMicrocmsNews } = useStaticQuery(graphql`
    query {
      allMicrocmsNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id: newsId
            title
            date
            formattedDate: date(formatString: "YYYY年MM月DD日")
          }
        }
      }
    }
  `)
  const posts = allMicrocmsNews.edges.map(({ node }) => node)
  return (
    <StyledNews>
      <TitleLeft
        level={2}
        fontSize="20px"
        heading="お知らせ"
      />
      <Posts
        posts={posts}
        limit={5}
        slug="news"
        category="お知らせ"
      />
    </StyledNews>
  )
}

export default News
