import React from "react"
import styled from "styled-components"
import { InView } from "react-intersection-observer"

const StyledContact = styled.div`
  position: relative;

  display: none;
`
/*
  @media (max-width: 959px) {
    display: none;
  }
*/

const Wrapper = styled(InView)`
  background: #ffffff;
  bottom: 0;
  height: 300px;
  left: 0;
  position: absolute;
  width: 100%;
`

const Contact = ({ setShowContact }) => {
  return (
    <StyledContact>
      <Wrapper
        rootMargin="-100px 0px 0px"
        onChange={inView => setShowContact(!inView)}
      />
    </StyledContact>
  )
}

export default Contact
