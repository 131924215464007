import {
  useStaticQuery,
  graphql
} from "gatsby"

const useBlog = () => {
  const { allMicrocmsBlog } = useStaticQuery(graphql`
    query {
      allMicrocmsBlog(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            date
            formattedDate: date(formatString: "YYYY年MM月DD日")
            reference {
              before {
                url
              }
              after {
                url
              }
            }
          }
        }
      }
    }
  `)
  const posts = allMicrocmsBlog.edges.map(({ node }) => node)
  return posts
}

export default useBlog
