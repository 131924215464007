import React, {
  useState,
  useEffect,
  useRef
} from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import {
  useStaticQuery,
  graphql
} from "gatsby"
import {
  getImage,
  GatsbyImage
} from "gatsby-plugin-image"
// components
import Dots from "../molecules/dots"

const StyledVisual = styled.div`
  background: #67a70c;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: auto auto;
  margin-top: 15px;
`

const OuterWrapper = styled.div`
  grid-column-end: -1;
  grid-column-start: 2;
  margin-top: -15px;
  overflow: hidden;
  position: relative;
`

const InnerWrapper = styled.div`
  bottom: ${props => props.bottom};
  float: ${props => props.float};
  left: ${props => props.left};
  position: ${props => props.position};
`

const Visual = () => {
  const [current, setCurrent] = useState(0)
  const isDesktop = useMediaQuery({ minWidth: 960 })
  const {
    visual_1,
    visual_960_1,
    visual_2,
    visual_960_2,
    visual_3,
    visual_960_3,
    catch_1,
    catch_2,
    catch_3
  } = useStaticQuery(graphql`
    query {
      visual_1: file(relativePath: { eq: "visual_1.JPG" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      visual_960_1: file(relativePath: { eq: "visual_960_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      visual_2: file(relativePath: { eq: "visual_2.JPG" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      visual_960_2: file(relativePath: { eq: "visual_960_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      visual_3: file(relativePath: { eq: "visual_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      visual_960_3: file(relativePath: { eq: "visual_960_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 300,
            layout: FIXED
          )
        }
      }
      catch_1: file(relativePath: { eq: "visual_catch_1.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED,
            width: 250
          )
        }
      }
      catch_2: file(relativePath: { eq: "visual_catch_2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED,
            width: 250
          )
        }
      }
      catch_3: file(relativePath: { eq: "visual_catch_3.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED,
            width: 250
          )
        }
      }
    }
  `)
  const images = [
    {
      image: isDesktop ? getImage(visual_1) : getImage(visual_960_1),
      alt: "" //
    },
    {
      image: isDesktop ? getImage(visual_2) : getImage(visual_960_2),
      alt: "" //
    },
    {
      image: isDesktop ? getImage(visual_3) : getImage(visual_960_3),
      alt: "" //
    }
  ]
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrent(_current => (_current + 1) % images.length)
    }, 5000)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [images.length])
  const intervalRef = useRef(null)
  const catches = [
    {
      image: getImage(catch_1),
      alt: "" //
    },
    {
      image: getImage(catch_2),
      alt: "" //
    },
    {
      image: getImage(catch_3),
      alt: "" //
    }
  ]
  const handleClick = index => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
    setCurrent(index)
  }
  return (
    <StyledVisual>
      <OuterWrapper>
        <InnerWrapper float={!isDesktop && "right"}>
          <GatsbyImage
            image={images[current].image}
            alt={images[current].alt}
          />
        </InnerWrapper>
        <InnerWrapper
          bottom="15px"
          left="15px"
          position="absolute"
        >
          <GatsbyImage
            image={catches[current].image}
            alt={catches[current].alt}
          />
        </InnerWrapper>
      </OuterWrapper>
      <Dots
        gridColumnEnd="-2"
        gridColumnStart="2"
        justifyContent="center"
        padding="10px 0"
        length={images.length}
        current={current}
        handleClick={handleClick}
      />
    </StyledVisual>
  )
}

export default Visual
