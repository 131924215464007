import React from "react"
import styled from "styled-components"
// components
import Heading1 from "../atoms/heading_1"
import Heading2 from "../atoms/heading_2"

const components = {
  Heading1,
  Heading2
}

const StyledTitleLeft = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;

  &::after {
    background: #e7e7eb;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    right: 0;
    width: 80%;
  }

  &::before {
    background: #67a70c;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    width: 20%;
  }
`

const TitleLeft = ({
  level,
  ...rest
}) => {
  const Component = components[`Heading${level}`]
  return (
    <StyledTitleLeft>
      <Component {...rest} />
    </StyledTitleLeft>
  )
}

export default TitleLeft
