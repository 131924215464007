import React, { useState } from "react"
import styled from "styled-components"
// components
import Layout from "../components/layout"
import Visual from "../components/organisms/visual"
import News from "../components/organisms/news"
import Contact from "../components/organisms/contact"
import Categories from "../components/organisms/categories"
import Case from "../components/organisms/case"
import Area from "../components/organisms/area"
import Workflow from "../components/organisms/workflow"
import SEO from "../components/seo"

const OuterWrapper = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
`

const InnerWrapper = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: 15px 0;

  @media (max-width: 767px) {
    overflow: hidden;
  }
`
/*
  @media (min-width: 960px) {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 615px auto;
    grid-template-rows: inherit;
  }
*/

const Home = () => {
  const [showContact, setShowContact] = useState(false)
  return (
    <Layout showContact={showContact}>
      <Visual />
      <OuterWrapper>
        <InnerWrapper>
          <News />
          <Contact setShowContact={setShowContact} />
        </InnerWrapper>
      </OuterWrapper>
      <Categories />
      <Case />
      <Area />
      <Workflow />
    </Layout>
  )
}

export default Home

export const Head = () => (
  <SEO />
)
