import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
// components
import Image from "./image"

const StyledBeforeAfter = styled.div`
  margin-bottom: ${props => props.marginBottom};

  @media (min-width: 768px) {
    display: flex;
  }
`

const BeforeAfter = ({
  asymmetric,
  imageBefore,
  altBefore,
  imageAfter,
  altAfter,
  ...rest
}) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return (
    <StyledBeforeAfter {...rest}>
      <Image
        flex={isNotMobile && "1"}
        marginBottom={asymmetric && isNotMobile && "100px"}
        src={imageBefore}
        alt={altBefore}
        label={
          asymmetric && isNotMobile
            ? "beforeOut"
            : "beforeIn"
        }
      />
      <Image
        flex={isNotMobile && "1"}
        marginLeft={asymmetric && isNotMobile && "-50px"}
        marginTop={asymmetric && isNotMobile && "100px"}
        src={imageAfter}
        alt={altAfter}
        label={
          asymmetric && isNotMobile
            ? "afterOut"
            : "afterIn"
        }
      />
    </StyledBeforeAfter>
  )
}

export default BeforeAfter
